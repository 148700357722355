(function () {
	window.sonar = {
		amplitude_api_key: '!SONAR_AMPLITUDE_API_KEY',
		churn_zero_app_key: '!SONAR_CHURN_ZERO_APP_KEY',
		fullstory_org_id: '!SONAR_FULLSTORY_ORG_ID',
		pendo_api_key: '!SONAR_PENDO_API_KEY',
		salesforceClientId: '!SONAR_SALESFORCE_CLIENT_ID',
		application_insights_connection_string: '!SONAR_APPLICATION_INSIGHTS_CONNECTION_STRING'
	};

	localStorage.setItem('api', '!SONAR_API');
})();
